/* Global CSS */
html,
body,
#root {
  height: 100%;
}

.container {
  height: 100%;
}

.pageContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.homeContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.homeBackground {
  background-size: cover;
  height: 100%;
  width: 100%;
}

.aboutCard {
  padding: 50px 500px 15px 50px;
}

.homeIntro {
  display: flex;
  flex-direction: row;
  padding-top: 175px;
  padding-bottom: 275px;
  color: white;
  font-family: 'Figtree', sans-serif;
}
.homeText{
  margin: 0 600px 0 100px;
  font-weight: 700;
  font-size: 36px;
}

.nav-transparent {
  background-color: transparent !important;
}

.nav {
  background-color: #4CA0AB;
  font-family: 'Figtree', sans-serif;
  font-weight: 700;
  font-size: 20px;
}

/* Map CSS */
.mapContainer {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: row;

  overflow: hidden;
}

.leaflet-container {
  flex-grow: 3;
}

.locationsContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 50vw;
}

.locationsList {
  overflow-y: auto;
  width: 100%;

  padding: 20px;
}

#search {
  border-color: black;
  margin: 20px;
  max-width: 95%;
}

.eventGroup {
  box-sizing: border-box;
  /* outline: 1px solid black; */
  background-color: rgb(220, 220, 220);
  /* background-color: black;
  color: white; */
  border-radius: 0.5rem;
  margin-bottom: 20px;
  padding: 10px;

  display: flex;
  flex-direction: row;
  height: 150px;
  width: 100%;
}

.eventGroup.focus {
  outline: 5px solid red !important;
}

.eventText {
  display: inline-block;
  margin-left: 10px;
  flex-grow: 3;
  min-width: 0;
}

.eventText>h1 {
  font-size: 1.5rem;
  font-weight: bold;

  margin: 0;
}

.eventText>h1>a {
  color: black;
}

.eventText>.host {
  font-size: 1rem;

  margin: 0;
}

.eventText>.dates {
  font-size: 1rem;
  font-style: italic;

  margin: 0;
}

.eventText>.description {
  margin: 0;
  width: 100%;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  /* display: block; */
}

/* Login CSS */
.loginContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;

  overflow: hidden;

  padding: 50px;
}

/* .iconLoginGroup {
  border-radius: 0.5rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

  display: flex;
  flex-direction: row;
} */

/* .logo {
  background-color: rgb(226, 230, 185);
  border-radius: 0.5rem 0 0 0.5rem;
} */

.registerDiv {
  background-color: rgb(220, 220, 220);
  border-radius: 0.5rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

  display: inline-block;
  padding: 50px;

  min-width: 50vw;
  overflow-y: auto;

  text-align: center;
}

.registerDiv>h2 {
  font-size: 3rem;
  margin-bottom: 40px;
}

.registerDiv>.inputGroup {
  text-align: left;
  display: flex;

  margin-bottom: 20px;
}

.inputGroup>label {
  flex-shrink: 0;
  font-size: 1.5rem;
  margin-right: 10px;
}

.registerDiv>input {
  display: block;
  width: 20vw;
  padding: 10px;
  margin: 20px 0;

  font-size: 1.5rem;
}

.registerDiv>.btn-primary {
  width: 100%;
  margin-top: 20px;
}

hr {
  margin: 20px 0 !important;
}

.registerDiv>.btn-success {
  background-color: rgb(14, 187, 14) !important;

  width: 100%;
}

.iconImg {
  object-fit: cover;
  width: 100%;
  height: 50px;
  padding: 0 40px 0 0;
}

.navbar-brand,
.nav-link {
  color: #C0D31F !important;
  text-shadow: 1px 1px 2px black;
}

.nav-link:hover {
  color: #bbc114 !important;
}